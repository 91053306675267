import { LogDiscord } from "../../utils/LogDiscord";
import { handlePaymentProps } from "../../utils/types";
import { alertNewReservation } from "./alertNewReservation";
import { formatApptData } from "./formatApptData";
import { getParsedFinalPrice } from "./getParsedFinalPrice";
import { getPaymentSession } from "./getPaymentSession";
import { storeLoggedInStorage } from "./storeLoggedInStorage";
import { storeLoggedInStorageCoupon } from "./storeLoggedInStorageCoupon";
//import { storeReservationInStorage } from "./storeReservationInStorage"; // commenter le temps de la campagne sms pour ne pas se connecter en faisant la reservation

export const handlePayment = async (props: handlePaymentProps) => {
  // 1. alerte temptative de réservation en cours
  alertNewReservation(props);
  // 3. on formate le prix final + applique le code promo s'il existe
  const salePrice = await getParsedFinalPrice(
    props.initialPrice,
    props.codePromo,
    props.paymentMethod
  );

  // 4. formatage des dates de début et de fin + création de la description pour formater les données de la réservation.
  const { resDateDebut, resDateFin, description } = formatApptData(
    props.date,
    props.creneau,
    props.duration,
    props.client.lastname,
    props.client.firstname,
    props.client.email
  );

  const responseCheckAvailable = await fetch(`${import.meta.env.VITE_GIFTCARD_API_URL}/check-appt-overlapping`,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      start: resDateDebut,
      end: resDateFin,
    }),
  });

  const data = await responseCheckAvailable.json();
  
  if(data.hasOverlappingAppointments){
    props.setErrors("Le créneau que vous avez sélectionné n'est plus disponible, veuillez en choisir un autre.");
    return;
  }

  let session = null;

  // 5. on check la methode de paiment
  // si la methode de paiment est offline  ou coupon :
  if (props.paymentMethod === "offline" || props.paymentMethod === "coupon") {
    // 6. on enregistre la réservation dans le localstorage et on redirige vers la page de confirmation

    storeLoggedInStorageCoupon(
      props.service,
      resDateDebut,
      resDateFin,
      props.codecoupon || "1",
      props.paymentMethod,
      props.client
    );
    //window.location.href = "/success-appt";
    return;
  } else if (props.paymentMethod === "online") {
    // sinon si la methode de paiment est online :

    // 7. on créer la session stripe
    session = await getPaymentSession(
      salePrice,
      props.service.name,
      props.paymentMethod,
      description,
      props.client.email
    );

    // 8. on enregistre la réservation dans le localstorage
    if (session && session.url) {
      storeLoggedInStorage(
        props.service,
        resDateDebut,
        resDateFin,
        salePrice,
        props.client,
        session,
        props.paymentMethod
      );
      // 9. on redirige vers la page de paiement
      //window.location.href = session.url;
    } else {
      // si la session n'est pas créée on affiche une erreur.
      props.setErrors(
        "Une erreur est survenue lors de la réservation, veuillez réessayer dans quelques instants."
      );
      LogDiscord(
        "error fonction getPaymentSession (online pay): " + session,
        "content.js"
      );
    }
  }
};
