import { FbOrm } from "@/utils/fborm";
import { addMinutes, endOfDay, format, parse, startOfDay } from "date-fns";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import checkCommonServiceAvailabillity from "../utils/checkCommonServiceAvailabillity";
import { Appointment, Service } from "../utils/types";

type CreneauxProps = {
  selectedcreneau: string;
  setcreneau: (creneau: string) => void;
  duree: string;
  idpresta: string;
  selectedDate: string;
};
const Creneaux = (props: CreneauxProps) => {
  const [heures, setHeures] = useState<string[]>([]);
  const [dateEstPasse, setDateEstPasse] = useState(false);
  const minutes = ["00", "15", "30", "45"];
  const parsedDate = parse(props.selectedDate, "dd/MM/yyyy", new Date());
  const selectedDate = format(parsedDate, "yyyy-MM-dd");
  const startOfSelectedDay = startOfDay(parsedDate);
  const endOfSelectedDay = endOfDay(parsedDate);
  const dureePrestation = props.duree;
  const WEEK_START_HOUR = 11;
  const SUNDAY_START_HOUR = 13;
  const END_HOUR = 19;

  const dureeEnMinutes = (duree: string): number => {
    console.log(duree);

    // Mettre en minuscule pour un traitement uniforme
    const duration = duree.toLowerCase();

    let strHours = "0";
    let strMinutes = "0";

    if (duration.includes("h")) {
      const time = duration.split("h");
      strHours = time[0] || "0"; // Gestion des cas où "h" est présent sans nombre avant
      strMinutes = time[1] || "0"; // Gestion des cas où rien n'est après "h"
    } else if (duration.includes("m")) {
      strMinutes = duration.split("m")[0]; // Minutes seules
    } else {
      strMinutes = duration; // Supposer que tout est en minutes
    }

    // Convertir les heures et minutes en nombres
    const hours = parseInt(strHours, 10) || 0;
    const minutes = parseInt(strMinutes, 10) || 0;

    // Calculer le total en minutes
    const totalMinutes = hours * 60 + minutes;

    console.log(`Hours: ${hours}, Minutes: ${minutes}, Total: ${totalMinutes}`);
    return totalMinutes;
  };

  const addintervalToDuration = async (idpresta: string) => {
    const DATA_PRESTATION = [
      {
        name: "prestations",
        id: idpresta,
      },
    ];
    const prestationsData = await FbOrm(DATA_PRESTATION);

    if (prestationsData.prestations.addIntervalTime) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const now = new Date();
    const startOfToday = startOfDay(now);
    if (startOfSelectedDay < startOfToday) {
      setDateEstPasse(true); // Indique que la date est passée
      setHeures([]); // Vide la liste des créneaux
      return;
    } else {
      setDateEstPasse(false); // Réinitialise l'état si la date est future ou aujourd'hui
    }

    // récupérer l'id du prestataire
    const getprestataire = async () => {
      const prestadoc = doc(db, "prestations", props.idpresta);
      const snap = await getDoc(prestadoc);
      if (!snap.exists()) return;
      return snap.data().id_prestataire;
    };

    const getPrestationsOfPrestataire = async () => {
      const prestataireId = await getprestataire();
      const prestationsRef = collection(db, "prestations");
      const q = query(
        prestationsRef,
        where("id_prestataire", "==", prestataireId)
      );
      const snapshot = await getDocs(q);
      const prestations: Service[] = [];
      snapshot.docs.map((doc) => {
        prestations.push({ ...doc.data(), id: doc.id } as Service);
      });
      return prestations;
    };

    const getAppointments = async () => {
      const prestations = await getPrestationsOfPrestataire();
      const prestataireId = await getprestataire();
      const appointmentsCol = collection(db, "appointments");
      const startTimestamp = Timestamp.fromDate(startOfSelectedDay);
      const endTimestamp = Timestamp.fromDate(endOfSelectedDay);

      const q = query(
        appointmentsCol,
        where("datedebut", ">=", startTimestamp),
        where("datedebut", "<=", endTimestamp)
      );
      const snapshot = await getDocs(q);
      const appointments: Appointment[] = [];
      snapshot.docs.map((doc) => {
        const rdv = { ...doc.data(), id: doc.id } as Appointment;
        if (prestations.some((prestation) => prestation.id === rdv.idpresta)) {
          if (rdv.status !== "cancelled") {
            appointments.push(rdv);
          }
        } else if (
          rdv.idprestataire === prestataireId &&
          rdv.status !== "cancelled"
        ) {
          appointments.push(rdv);
        }
      });
      const commonAppts = await checkCommonServiceAvailabillity(
        props.idpresta,
        selectedDate
      );
      if (commonAppts.length > 0) {
        commonAppts.forEach((rdv) => {
          if (rdv.status !== "cancelled") {
            appointments.push(rdv);
          }
        });
      }
      generateHeures(appointments);
    };

    getAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dureePrestation]);

  // Générer les créneaux de 11h à 19h
  const generateHeures = async (appointments: Appointment[]) => {
    const generatedHeures = [];
    const START_HOUR =
      startOfSelectedDay.getDay() === 0 ? SUNDAY_START_HOUR : WEEK_START_HOUR;

    const now = new Date();
    const isToday = now.toDateString() === startOfSelectedDay.toDateString();
    const addInterval = await addintervalToDuration(props.idpresta);

    for (let heure = START_HOUR; heure <= END_HOUR; heure++) {
      for (const minute of minutes) {
        if (heure === END_HOUR && minute !== "00") break;

        const creneau = new Date(`${selectedDate} ${heure}:${minute}`);

        let totalMinutes = dureeEnMinutes(dureePrestation);
        if (addInterval) {
          totalMinutes += 15;
          //console.log("Interval added");
        }
        const finCreneau = addMinutes(creneau, totalMinutes);

        // Si c'est aujourd'hui, on ne prend pas en compte les créneaux passés
        if (isToday && creneau <= now) {
          continue;
        }

        const isTaken = appointments.some((rdv) => {
          const datedebut = rdv.datedebut.toDate();
          const datefin = rdv.datefin.toDate();
          return (
            (creneau < datefin && finCreneau > datedebut) ||
            finCreneau > endOfSelectedDay
          );
        });

        if (!isTaken) {
          generatedHeures.push(`${heure}:${minute}`);
        }
      }
    }

    setHeures(generatedHeures);
  };

  return (
    <div className="bg-white mb-4">
      <h1 className="text-center text-2xl font-bold">Choisir un Créneau :</h1>
      {dateEstPasse ? (
        // Si la date est passée, afficher un message
        <p className="text-center text-red-600">
          Aucun créneau disponible pour cette date passée.
        </p>
      ) : (
        <div className="creneaux w-10/12 md:w-8/12 lg:w-6/12 mx-auto rounded-lg border border-solid border-slate-200 flex flex-wrap justify-center my-4">
          {heures.map((creneau, index) => {
            // Ajoutez des styles conditionnellement si le créneau est celui sélectionné
            const isSelected = props.selectedcreneau === creneau;
            const bgColor = isSelected ? "bg-black" : "bg-slate-300";
            const textColor = isSelected ? "text-white" : "text-black";
            const hoverStyles = !isSelected
              ? "hover:bg-black hover:text-white"
              : "";

            return (
              <div
                key={index}
                className={`creneau w-full md:w-3/12 xl:w-1/12 rounded-xl p-2 text-center font-bold ${bgColor} ${textColor} m-2 ${hoverStyles}`}
                onClick={() => {
                  props.setcreneau(creneau as string);
                }}
              >
                {creneau}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Creneaux;
