import { Timestamp } from "firebase/firestore";
import { LogDiscord } from "../../utils/LogDiscord";
import { Client, Service } from "../../utils/types";

export const storeLoggedInStorageCoupon = (
  service: Service,
  resDateDebut: Timestamp,
  resDateFin: Date,
  codecoupon: string,
  paymentMethod: string,
  client: Client
) => {
  try {
    let description = "";
    if (!client.firstname && !client.lastname) {
      description = " pour " + client.email;
    } else {
      description = " pour " + client.lastname + " " + client.firstname;
    }

    const currentReservationData = {
      idpresta: service.id,
      datedebut: resDateDebut,
      datefin: Timestamp.fromDate(resDateFin),
      idprestataire: service.id_prestataire,
      name: service.name,
      description,
      clientId: client.id,
      clientEmail: client.email,
      payment: paymentMethod,
      codeCoupon: codecoupon,
      createdAt: Timestamp.now(),
    };

    localStorage.setItem(
      "currentReservation",
      JSON.stringify(currentReservationData)
    );
  } catch (error) {
    LogDiscord(
      "error fonction storeLoggedInStorageCoupon : " + error,
      "content.js"
    );
    const errorMessage = [
      "```json",
      " service :",
      JSON.stringify(service),
      " resDateDebut :",
      resDateDebut,
      " resDateFin :",
      resDateFin,
      " client :",
      JSON.stringify(client),
      " codecoupon :",
      codecoupon,
      " paymentMethod :",
      paymentMethod,
      "```",
    ].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
