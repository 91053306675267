/* eslint-disable @typescript-eslint/no-explicit-any */
//import { } from "@firebase/firestore";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import {
  Timestamp,
  WhereFilterOp,
  addDoc,
  collection,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import CheckoutForm from "../components/CheckoutForm";
import { useAuth } from "../contexts/AuthContext";
import { LogDiscord } from "../utils/LogDiscord";
import {
  addDurationToDate,
  convertToFirebaseTimestamp,
} from "../utils/datetime";
import { FbOrm } from "../utils/fborm";
import { Client, Service } from "../utils/types";

const PaymentPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [resa, setResa] = useState<any>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [prestaPrix, setPrestaPrix] = useState<number | null>(null);

  const loadStripeObject = async () => {
    try {
      const stripeObj = await loadStripe(
        import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
      );
      if (stripeObj) {
        setStripePromise(stripeObj);
      }
    } catch (error) {
      LogDiscord("erreur fonction loadStripe : " + error, "ReservationPage");
    }
  };
  useEffect(() => {
    try {
      if (!currentUser) {
        navigate("/login");
      }
      const data = localStorage.getItem("pendingReservation");
      if (!data) {
        LogDiscord("No pending reservation data found", "error PaymentPagev2");
      } else if (data && currentUser) {
        const parsedData = JSON.parse(data);
        // récupérer le client et la prestation
        loadStripeObject();
        getClientAndService(parsedData);
      }
    } catch (err: any) {
      LogDiscord(err.message, "error PaymentPagev2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientAndService = async (data: any) => {
    if (!currentUser) return;
    const collectionsToFetch = [
      {
        name: "clients",
        where: [
          {
            field: "email",
            operator: "==" as WhereFilterOp,
            value: currentUser.email,
          },
        ],
      },
      {
        name: "prestations",
        id: data.serviceId,
      },
    ];
    const fetchedData = await FbOrm(collectionsToFetch);
    if (
      !fetchedData.clients ||
      fetchedData.clients.length === 0 ||
      !fetchedData.prestations
    ) {
      LogDiscord(
        "No client found for email : " + currentUser.email,
        "PaymentPage"
      );
      return;
    }

    const prestaData = fetchedData.prestations as Service;
    data = {
      ...data,
      idprestataire: prestaData.id_prestataire,
      clientId: fetchedData.clients?.at(0).id,
      name: prestaData.name,
    };
    setClient(fetchedData.clients.at(0));
    setPrestaPrix(data.pricing);
    setResa(data);
  };

  const handlePayment = async () => {
    // Créer la réservation
    // Mettre à jour le client avec la nouvelle réservation
    // créer l'objet currentReservation pour la page success
    // Supprimer la réservation en attente
    // payer la réservation
    try {
      if (!resa || !client || !prestaPrix) {
        LogDiscord("missing data", "PaymentPage");
        return;
      }
      if (resa.payment !== "coupon" && resa.payment !== "offline") {
        // 1. on crée une session de paiement avec les infos de la prestation
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/create-checkout-session-appt",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              price: prestaPrix, // This should be your service amount, make sure to calculate the total cost or deposit
              name: resa.name, // Description of the service
              description:
                " pour " +
                client.lastname +
                " " +
                client.firstname +
                " le " +
                resa.date +
                " à " +
                resa.creneau, // Description of the service
              payment: resa.payment, // This should be the payment type, online or deposit
              // Include any other necessary data
            }),
          }
        );

        const session = await response.json();

        const resDateDebut = convertToFirebaseTimestamp(
          resa.date,
          resa.creneau
        );
        if (!resDateDebut) {
          LogDiscord("erreur resDateDebut : fct handlepayment", "PaymentPage");
          return;
        }
        const resDateFin = addDurationToDate(
          resDateDebut.toDate(),
          resa.duration
        );
        // ajouter en avance la réservation dans la base de données
        const apptRef = collection(db, "appointments");
        await addDoc(apptRef, {
          name: resa.name,
          status: "pending",
          description:
            " pour " +
            client.lastname +
            " " +
            client.firstname +
            "from payment page",
          datedebut: resDateDebut,
          datefin: Timestamp.fromDate(resDateFin),
          idpresta: resa.serviceId,
          idprestataire: resa.idprestataire,
          clientId: client.id,
          codeCoupon: resa.codecoupon,
          paymentIntentId: "",
          payment: resa.payment,
          createdAt: Timestamp.now(),
        });

        // 2. on garde les infos de la réservation dans le local storage
        localStorage.setItem(
          "currentReservation",
          JSON.stringify({
            idpresta: resa.serviceId,
            idprestataire: resa.idprestataire,
            datedebut: resDateDebut,
            datefin: Timestamp.fromDate(resDateFin),
            name: resa.name,
            payment: resa.payment,
            description: " pour " + client.lastname + " " + client.firstname,
            clientId: client.id,
            sessionId: session.id,
          })
        );
        LogDiscord("reservation sur paymentPage en cours", "PaymentPage");
        LogDiscord(
          "```json\n" +
            JSON.stringify({
              idpresta: resa.serviceId,
              idprestataire: resa.idprestataire,
              date: resa.date,
              creneau: resa.creneau,
              name: resa.name,
              payment: resa.payment,
              description: " pour " + client.lastname + " " + client.firstname,
              clientId: client.id,
              sessionId: session.id,
            }) +
            "\n```",
          "PaymentPage"
        );
        localStorage.removeItem("pendingReservation");

        // 3. si le paiement est offline, on garde le client secret dans le state et on affiche le formulaire de paiement de l'accompte
        if (resa.payment === "offline") {
          setClientSecret(session.clientSecret);
        }
        // 4. si le paiement est online, on redirige le client vers la page de paiement stripe
        else {
          // Redirect to Stripe Checkout
          window.location.href = session.url;
        }
      } else {
        // 8. si le paiement est un coupon, on envoie un mail de confirmation
        const resDateDebut = convertToFirebaseTimestamp(
          resa.date,
          resa.creneau
        );
        if (!resDateDebut) {
          LogDiscord("erreur resDateDebut : fct handlepayment", "PaymentPage");
          return;
        }
        const resDateFin = addDurationToDate(
          resDateDebut.toDate(),
          resa.duration
        );

        localStorage.setItem(
          "currentReservation",
          JSON.stringify({
            idpresta: resa.serviceId,
            datedebut: resDateDebut,
            datefin: Timestamp.fromDate(resDateFin),
            name: resa.name,
            description: " pour " + client.lastname + " " + client.firstname,
            clientId: client.id,
            codeCoupon: resa.codecoupon || "1",
          })
        );
        localStorage.removeItem("pendingReservation");
        window.location.href = "/success-appt";
      }
    } catch (error) {
      LogDiscord(
        "Une erreur est survenue lors de la réservation : " +
          error +
          "pour " +
          currentUser?.email +
          "resa : " +
          JSON.stringify(resa) +
          "prix : " +
          prestaPrix,
        "PaymentPage"
      );
    }
  };

  if (!resa || !resa.name || !client || !client.id || !prestaPrix)
    return <div className="text-black mb-8 min-h-[70vh] my-4">loading...</div>;
  return (
    <div className="text-black mb-8 min-h-[70vh] my-4">
      {resa.payment === "offline" &&
        stripePromise &&
        clientSecret &&
        clientSecret !== "" && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
            }}
          >
            <CheckoutForm prestaprice={prestaPrix} isGift={false} />
          </Elements>
        )}
      {resa.name &&
        (!clientSecret || clientSecret === "" || resa.payment === "online") && (
          <div>
            <div className="service-reserved my-2">
              <div className="bg-black text-white w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 rounded-md">
                <h2 className="text-2xl font-bold">{resa.name}</h2>
                <p className="text-xl">{resa.duration}</p>
              </div>
            </div>

            <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 bg-slate-200 rounded-lg">
              <h2 className="text-2xl font-bold">Récapitulatif :</h2>
              <p className="text-xl">
                <strong>{resa.name}</strong>
              </p>
              <p className="text-xl">
                durée : <strong>{resa.duration}</strong>
              </p>
              <p className="text-xl">
                le <strong>{resa.date}</strong> à{" "}
                <strong>{resa.creneau}</strong>
              </p>
              <p className="text-xl">
                pour {client.lastname} {client.firstname}
              </p>
            </div>

            <div className="actions sticky md:relative bottom-0 bg-white">
              <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto flex justify-between pt-4">
                <button
                  onClick={() => {
                    handlePayment();
                  }}
                  className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PaymentPage;
