import { useAuth } from "@/contexts/AuthContext";
import { FbOrm } from "@/utils/fborm";
import { Service, ServiceDuration } from "@/utils/types";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  Timestamp,
  WhereFilterOp,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../../../firebase";
import { LogDiscord } from "../../utils/LogDiscord";
import Calendrier from "../Calendar";
import Creneaux from "../Creneaux";
import BottomBtns from "./_components/bottombtns";
import { handlePayment } from "./content";

const ReservationPage = () => {
  // reservation?id=2Ebi701K0wNoxtWm91qV&duration=01h&payment=online
  //paramètre de l'url :
  //id : id de la prestation
  //duration : durée de la prestation selectionné
  //payment : mode de paiement selectionné
  //codecoupon : code coupon groupon ou carte cadeau
  //codePromo : code promo
  const [searchParams] = useSearchParams();
  const duration = searchParams.get("duration");
  const serviceId = searchParams.get("id");
  const paymentMethod = searchParams.get("payment");
  const codecoupon = searchParams.get("codecoupon");
  const codePromo = searchParams.get("codePromo");

  const { currentUser } = useAuth(); // récupérer l'utilisateur connecté

  // USE STATE

  const [step, setStep] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialPrice, setInitialPrice] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [service, setService] = useState<Service>();
  const [date, setDate] = useState("");
  const [creneau, setCreneau] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [errors, setErrors] = useState("");

  // USE EFFECTS

  useEffect(() => {
    // 1. on récupère la prestation que le client veut réserver
    const getService = async () => {
      // ref de la prestation spécifique basée sur l'ID
      if (!serviceId) return;
      const docRef = doc(db, "prestations", serviceId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setService({ ...docSnap.data(), id: docSnap.id } as Service);
          setInitialPrice(
            docSnap
              .data()
              .durations.find((d: ServiceDuration) => d.dur === duration).price
          );
        }
      } catch (error) {
        LogDiscord(
          "erreur fonction getService avec l'id " + serviceId + " : " + error,
          "ReservationPage"
        );
      }
    };

    getService();
  }, [serviceId, duration]);

  // préremplir l'email si l'utilisateur est connecté
  useEffect(() => {
    if (currentUser?.email) {
      setSelectedEmail(currentUser.email);
    }
  }, [currentUser]);
  // FONCTIONS

  const nbResaStatsCounter = () => {
    if (import.meta.env.MODE !== "production") {
      return;
    }

    const statsCollection = collection(db, "stats");
    const data = {
      name: "reservation btn clicked",
      date: Timestamp.now(),
    };
    addDoc(statsCollection, data);
  };

  const setNexStep = async (nextStep: number) => {
    if (nextStep === 2) {
      if (!date) {
        setErrors("Veuillez selectionner une date");
        return;
      }
    }

    if (nextStep === 3) {
      if (!creneau) {
        setErrors("Veuillez selectionner un créneau");
        return;
      }
    }
    if (nextStep === 4) {
      if (selectedEmail === "" || selectedPhone === "") {
        setErrors("Veuillez remplir tous les champs");
        return;
      }

      const client = await UpsertClient(selectedEmail, selectedPhone);
      if (!client || !duration || !paymentMethod || !service) {
        setErrors("Veuillez renseigner votre email");
        return;
      }

      nbResaStatsCounter();

      handlePayment({
        initialPrice,
        codePromo,
        paymentMethod,
        service,
        client,
        currentUser,
        duration,
        date,
        creneau,
        codecoupon,
        setStep,
        setErrors,
      });

      // créer la réservation et formater les données avec l'id du client
    }
    setErrors("");
    setStep(nextStep);
  };

  const addUserToSendgrid = async (email: string, phone: string) => {
    if (email === "" || phone === "") return;
    fetch(import.meta.env.VITE_GIFTCARD_API_URL + "/marketing/addOne", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, phone }),
    });
  };

  const createClient = async (email: string, phone: string) => {
    if (email === "" || phone === "") return;

    const newClientData = {
      firstname: "",
      lastname: "",
      email,
      phone,
      adress: "",
      city: "",
      zipcode: "",
      country: "",
      notes: "",
      birthdate: "",
      emailPromotions: true,
      smsReminders: true,
      status: "created from first reservation",
      createdAt: Timestamp.now(),
    };
    try {
      // Ajouter le nouveau client
      const clientCollection = collection(db, "clients");
      const docRef = await addDoc(clientCollection, newClientData);

      //ajouter le nouveau client à firebase auth
      await addUserToAuth(email);
      await addUserToSendgrid(email, phone);

      // Récupérer les données complètes du client, y compris l'ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Retourner un objet complet avec les données et l'ID
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error("Erreur : Le document n'existe pas après sa création.");
      }
    } catch (error) {
      console.error("Erreur lors de la création du client :", error);
      throw error; // Rejeter l'erreur pour la gestion ultérieure
    }
  };

  const addUserToAuth = async (email: string) => {
    if (email === "") return;
    fetch(import.meta.env.VITE_API_URL + "/createAuthAccountAndSendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  };

  const UpsertClient = async (email: string, phone: string) => {
    if (email === "" || phone === "") return;
    const Colletions_to_fetch = [
      {
        name: "clients",
        where: [
          {
            field: "email",
            operator: "==" as WhereFilterOp,
            value: email,
          },
        ],
      },
    ];
    const clientData = await FbOrm(Colletions_to_fetch);

    let clientToReturn = null;

    if (clientData.clients.length === 0) {
      // créer un nouveau client
      const newClient = await createClient(email, phone);
      clientToReturn = newClient;
      // return new
    } else clientToReturn = clientData.clients[0];
    return clientToReturn;
  };

  if (!service || !serviceId || !duration)
    return <div className="text-black mb-8 min-h-[70vh]">loading...</div>;

  return (
    <div className="text-black mb-8 min-h-[70vh]">
      {/* carte résumant les informations de réservation */}
      <div className="service-reserved my-2">
        <div className="bg-black text-white w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 rounded-md">
          <h2 className="text-2xl font-bold">{service.name}</h2>
          <p className="text-xl">{duration}</p>
        </div>
      </div>

      {/* erreurs */}
      <p className="text-center font-bold text-red-500">{errors}</p>

      {/* booking progression steps */}
      <div className="text-center my-4">
        <ul className="steps text-center">
          <li className={`step ${step >= 1 ? "step-primary" : ""}`}>
            Choisir une date
          </li>
          <li className={`step ${step >= 2 ? "step-primary" : ""}`}>
            Choisir un créneau
          </li>
          <li className={`step ${step >= 3 ? "step-primary" : ""}`}>
            Renseigner votre email
          </li>
          <li className={`step ${step >= 4 ? "step-primary" : ""}`}>
            Réserver
          </li>
        </ul>
      </div>

      {/* Step 1 */}
      {step === 1 && (
        <Calendrier setdate={setDate} idpresta={serviceId || ""} />
      )}

      {/* Step 2 */}
      {step === 2 && (
        <Creneaux
          selectedcreneau={creneau}
          setcreneau={setCreneau}
          duree={duration}
          idpresta={serviceId}
          selectedDate={date}
        />
      )}

      {/* Step 3 */}
      {step === 3 && (
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-bold text-center mb-4">
            Veuillez renseigner vos informations
          </h2>
          <label>Email</label>
          <input
            type="email"
            className="input bg-white border border-slate-900 focus:border-slate-900 border-solid mx-auto w-10/12 md:w-1/4"
            placeholder="Email"
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.target.value)}
          />
          <label>Téléphone</label>
          <input
            type="phone"
            className="input bg-white border border-slate-900 focus:border-slate-900 border-solid mx-auto w-10/12 md:w-1/4"
            placeholder="téléphone"
            value={selectedPhone}
            onChange={(e) => setSelectedPhone(e.target.value)}
          />
        </div>
      )}
      <BottomBtns currentStep={step} setNexStep={setNexStep} />
    </div>
  );
};

export default ReservationPage;
